define("frontend2/controllers/user", ["exports", "frontend2/controllers/base", "@ember/object", "@glimmer/tracking"], function (_exports, _base, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserController = _exports.default = (_class = class UserController extends _base.default {
    constructor(...args) {
      super(...args);
      // Invite dialog UI helpers
      _initializerDefineProperty(this, "wasInvitedDialog", _descriptor, this);
      _initializerDefineProperty(this, "wasReinvitedDialog", _descriptor2, this);
      // List of users types
      _defineProperty(this, "userTypeList", []);
    }
    get userTypeFilteredList() {
      return this.userTypeList.filter(type => type.level <= this.apiSession.current.get('user.userType.level'));
    }
    async postSaveHook(creating) {
      // We need to send invitation for new users
      // console.log(this.model);
      if (creating) {
        this.wasInvitedDialog = true;
        return new Promise(resolve => {
          this.apiSession.inviteUser(this.model).then(() => {
            console.debug('Invitation sent successfully');
            resolve(false);
          }).catch(err => {
            console.error('Invitation failed:');
            console.error(err);
          });
        });
      } else {
        console.debug('Skipping invitation for active user');
        return Promise.resolve(true);
      }
    }

    // INVITATION DIALOG
    closeInviteDialog() {
      this.wasInvitedDialog = false;
      this.router.transitionTo('users');
    }

    // REINVITATION DIALOG
    reinviteUser() {
      this.apiSession.inviteUser(this.model).then(() => {
        this.wasReinvitedDialog = true;
      });
    }
    closeReinviteDialog() {
      this.wasReinvitedDialog = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "wasInvitedDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "wasReinvitedDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "closeInviteDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeInviteDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "reinviteUser", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "reinviteUser"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeReinviteDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeReinviteDialog"), _class.prototype), _class);
});