define("frontend2/index", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "parse", {
    enumerable: true,
    get: function () {
      return _emberUuid.parse;
    }
  });
  Object.defineProperty(_exports, "unparse", {
    enumerable: true,
    get: function () {
      return _emberUuid.unparse;
    }
  });
  Object.defineProperty(_exports, "v1", {
    enumerable: true,
    get: function () {
      return _emberUuid.v1;
    }
  });
  Object.defineProperty(_exports, "v4", {
    enumerable: true,
    get: function () {
      return _emberUuid.v4;
    }
  });
});