define("frontend2/adapters/application", ["exports", "@ember-data/adapter/json-api", "@ember/service", "@ember-data/adapter/error", "frontend2/config/environment"], function (_exports, _jsonApi, _service, _error, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  // Translate polymorphic models into parent model (probably a better way to do this)
  const MODEL_TRANSLATION = {
    'call-flow-action-play-msg': 'call-flow-actions',
    'call-flow-action-hangup': 'call-flow-actions',
    'call-flow-action-ivr-menu': 'call-flow-actions',
    'call-flow-action-jump-call-flow': 'call-flow-actions',
    'call-flow-action-call-group': 'call-flow-actions',
    'call-flow-action-call-direct': 'call-flow-actions'
  };
  let ApplicationAdapter = _exports.default = (_class = class ApplicationAdapter extends _jsonApi.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "apiSession", _descriptor2, this);
      _defineProperty(this, "host", _environment.default.APP.API_HOST);
      _defineProperty(this, "namespace", 'api');
    }
    get headers() {
      let headers = {};
      // Use embed config token for auth?
      if (this.apiSession.embedAccessToken) {
        console.debug('Auth using embedded access token');
        headers['Authorization'] = `Bearer ${this.apiSession.embedAccessToken}`;
      } else if (this.session.isAuthenticated) {
        headers['Authorization'] = `Bearer ${this.session.data.authenticated.access_token}`;

        // Impersonate
        const imp_id = localStorage.getItem('impCid');
        if (imp_id) {
          console.debug(`Impersonating customer with id ${imp_id}`);
          headers['X-USE-CUSTOMER-ID'] = imp_id;
        }
      }
      return headers;
    }
    buildURL(modelName, id, record, queryType, query) {
      // If query has '_endpoint' use this as URL and skip normal url build
      if (query && query._endpoint) {
        // Get endpoint and remove from query string
        let ep = query._endpoint;
        delete query._endpoint;
        return `${this.host}/${this.namespace}/${ep}/`;
      }

      // Translate
      if (modelName in MODEL_TRANSLATION) {
        // console.debug(`Translating model name ${modelName} into ${MODEL_TRANSLATION[modelName]}`);
        modelName = MODEL_TRANSLATION[modelName];
      }

      // Just use default
      return super.buildURL(modelName, id, record, queryType, query) + '/';
    }

    // Handle server validation nicely
    handleResponse(status, headers, payload) {
      if (status === 400 && payload.errors) {
        console.info('Adapter error:');
        console.debug(payload.errors);
        return new _error.InvalidError(payload.errors);
      } else {
        return super.handleResponse(...arguments);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "apiSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});