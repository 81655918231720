define("frontend2/controllers/call-details", ["exports", "frontend2/controllers/base", "@ember/object", "@glimmer/tracking"], function (_exports, _base, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CallDetailsController = _exports.default = (_class = class CallDetailsController extends _base.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "countryList", null);
      _initializerDefineProperty(this, "idd", _descriptor, this);
      _initializerDefineProperty(this, "search", _descriptor2, this);
      _initializerDefineProperty(this, "interval", _descriptor3, this);
      // Initial state of collapsibles
      _initializerDefineProperty(this, "collapseRecordings", _descriptor4, this);
      _initializerDefineProperty(this, "collapseCost", _descriptor5, this);
      _initializerDefineProperty(this, "collapseMedia", _descriptor6, this);
      _initializerDefineProperty(this, "collapseAdvanced", _descriptor7, this);
      _defineProperty(this, "searchIntervalOptions", [{
        value: '24',
        name: 'Last 24 hours'
      }, {
        value: '168',
        name: 'Last week'
      }, {
        value: '744',
        name: 'Last month'
      }, {
        value: '8760',
        name: 'Last year'
      }]);
    }
    toggleRecordings() {
      this.collapseRecordings = !this.collapseRecordings;
    }
    toggleCost() {
      this.collapseCost = !this.collapseCost;
    }
    toggleMedia() {
      this.collapseMedia = !this.collapseMedia;
    }
    toggleAdvanced() {
      this.collapseAdvanced = !this.collapseAdvanced;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "idd", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "search", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "interval", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '168';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "collapseRecordings", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "collapseCost", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "collapseMedia", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "collapseAdvanced", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleRecordings", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleRecordings"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleCost", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCost"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMedia", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMedia"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAdvanced", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAdvanced"), _class.prototype), _class);
});