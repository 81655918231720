define("frontend2/templates/security/index", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "kiEMT1Ae",
    "block": "[[[3,\" Breadcrumb start \"],[1,\"\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,\"ul\"],[14,0,\"breadcrumb\"],[12],[1,\"\\n    \"],[10,\"li\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@route\"],[\"index\"]],[[\"default\"],[[[[1,\"Home\"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,\"li\"],[14,0,\"active\"],[12],[1,\"Security\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[3,\" Breadcrumb end \"],[1,\"\\n\\n\"],[3,\" Page Header start \"],[1,\"\\n\"],[10,0],[14,0,\"container page-header\"],[12],[1,\"\\n  \"],[10,\"h2\"],[12],[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\",\"@class\"],[\"shield-alt\",\"pull-left\"]],null],[1,\"\\n    Security and abuse protection\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"],[10,0],[14,0,\"container \"],[12],[1,\"\\n  \"],[10,0],[14,0,\"col-xs-12 col-sm-6 col-md-4 col-lg-3 mainpanel\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@class\",\"@tagName\"],[\"security.networks\",\"panel panel-default\",\"div\"]],[[\"default\"],[[[[1,\"\\n      \"],[10,0],[14,0,\"panel-body text-center\"],[14,5,\"cursor:pointer\"],[12],[1,\"\\n        \"],[8,[39,1],null,[[\"@icon\",\"@class\"],[\"server\",\"fa-2x\"]],null],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"\\n          Networks\\n        \"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"Trusted networks management\"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"link-to\",\"fa-icon\"]]",
    "moduleName": "frontend2/templates/security/index.hbs",
    "isStrictMode": false
  });
});