define("frontend2/routes/short-cid/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class ShortCidEditRoute extends _base.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "controllerName", 'short-cid');
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      controller.phoneNumberList = this.store.findAll('phone-number', {
        include: 'country'
      });
    }
    model(params) {
      return this.store.findRecord('short-cid', params.cid_id, {
        include: 'phone_numbers'
      });
    }
  }
  _exports.default = ShortCidEditRoute;
});