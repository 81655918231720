define("frontend2/controllers/login", ["exports", "frontend2/controllers/base", "@ember/object", "@glimmer/tracking", "ember-changeset-validations/validators"], function (_exports, _base, _object, _tracking, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserController = _exports.default = (_class = class UserController extends _base.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "identification", '');
      _defineProperty(this, "password", '');
      // Request password dialog
      _initializerDefineProperty(this, "requestPasswordCompleteDlg", _descriptor, this);
      _defineProperty(this, "email", '');
      // Recover password form validation
      _defineProperty(this, "recoverValidation", {
        email: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
          type: 'email'
        })]
      });
      _defineProperty(this, "loginValidation", {
        identification: [(0, _validators.validatePresence)(true), (0, _validators.validateFormat)({
          type: 'email'
        })],
        password: [(0, _validators.validatePresence)(true)]
      });
    }
    gotoLoginPage() {
      this.router.transitionTo('login');
    }
    login(cs) {
      this.apiSession.login(cs.identification, cs.password).then(() => {
        this.notify.success('Successfully logged in');
        this.router.transitionTo('index');
      }).catch(err => {
        cs.addError('password', err);
      });
    }

    // Request new password email
    requestPassword(cs) {
      this.apiSession.reqPasswordRecover(cs.email).then(() => {
        console.log('Password recover ok');
        this.requestPasswordCompleteDlg = true;
      }).catch(err => {
        console.error(err);
      });
    }
    requestPasswordDone() {
      this.requestPasswordCompleteDlg = false;
      this.router.transitionTo('login');
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "requestPasswordCompleteDlg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "gotoLoginPage", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "gotoLoginPage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "login", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "login"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPassword", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "requestPassword"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "requestPasswordDone", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "requestPasswordDone"), _class.prototype), _class);
});