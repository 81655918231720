define("frontend2/components/validation-error", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.message}}
  */
  {
    "id": "RdLM/MK/",
    "block": "[[[1,[30,0,[\"message\"]]]],[],false,[]]",
    "moduleName": "frontend2/components/validation-error.hbs",
    "isStrictMode": false
  });
  class ValidationErrorComponent extends _component2.default {
    get message() {
      if (this.args.errors) {
        const err = this.args.errors.filter(item => item.key === this.args.property);
        if (err.length) {
          console.debug(`Property "${this.args.property}" has ${err.length} validation errors`);
          // console.debug(err);
          return err[0].validation[0];
        }
      }
      return '';
    }
  }
  _exports.default = ValidationErrorComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ValidationErrorComponent);
});