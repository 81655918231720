define("frontend2/templates/embed-error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "yHl7Bhxl",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"jumbotron\"],[12],[1,\"\\n    \"],[10,\"h2\"],[12],[1,\"\\n      \"],[8,[39,0],null,[[\"@icon\"],[\"exclamation-triangle\"]],null],[1,\"\\n      Session timed out\\n    \"],[13],[1,\"\\n    \"],[10,2],[12],[1,\"Unable to process the request, because session timed out.\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n      Please refresh your browser and if problem persists, contact support to resolve the problem.\"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"fa-icon\"]]",
    "moduleName": "frontend2/templates/embed-error.hbs",
    "isStrictMode": false
  });
});