define("frontend2/templates/application", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "QLUxMjg+",
    "block": "[[[41,[51,[30,0,[\"apiSession\",\"embedAccessToken\"]]],[[[1,\"  \"],[1,[34,1]],[1,\"\\n\"]],[]],null],[1,\"\\n\\n\"],[46,[28,[37,3],null,null],null,null,null],[1,\"\\n\\n\"],[8,[39,4],null,[[\"@closeAfter\",\"@messageStyle\"],[4000,\"bootstrap\"]],null]],[],false,[\"unless\",\"top-nav\",\"component\",\"-outlet\",\"ember-notify\"]]",
    "moduleName": "frontend2/templates/application.hbs",
    "isStrictMode": false
  });
});