define("frontend2/components/confirm", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsModal @onHide={{action "cancelConfirm"}} @open={{this.args.show}} as |modal|>
  <modal.header>
    <h4 class="modal-title"><FaIcon @icon="exclamation-triangle"/> Please confirm</h4>
  </modal.header>
  <modal.body>{{yield}}</modal.body>
  <modal.footer as |footer|>
    <BsButton @onClick={{action "confirmed"}} @type="success">
      <FaIcon @icon="trash"/>
      Yes, delete
    </BsButton>
    <BsButton @onClick={{action "cancelConfirm"}} @type="danger">
      <FaIcon @icon="times"/>
      No, abort
    </BsButton>
  </modal.footer>
  </BsModal>
  
  */
  {
    "id": "MsViqG5x",
    "block": "[[[8,[39,0],null,[[\"@onHide\",\"@open\"],[[28,[37,1],[[30,0],\"cancelConfirm\"],null],[30,0,[\"args\",\"show\"]]]],[[\"default\"],[[[[1,\"\\n\"],[8,[30,1,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[8,[39,2],null,[[\"@icon\"],[\"exclamation-triangle\"]],null],[1,\" Please confirm\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[18,3,null]],[]]]]],[1,\"\\n\"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,4],null,[[\"@onClick\",\"@type\"],[[28,[37,1],[[30,0],\"confirmed\"],null],\"success\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"trash\"]],null],[1,\"\\n    Yes, delete\\n  \"]],[]]]]],[1,\"\\n  \"],[8,[39,4],null,[[\"@onClick\",\"@type\"],[[28,[37,1],[[30,0],\"cancelConfirm\"],null],\"danger\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],null,[[\"@icon\"],[\"times\"]],null],[1,\"\\n    No, abort\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"modal\",\"footer\",\"&default\"],false,[\"bs-modal\",\"action\",\"fa-icon\",\"yield\",\"bs-button\"]]",
    "moduleName": "frontend2/components/confirm.hbs",
    "isStrictMode": false
  });
  let ConfirmComponent = _exports.default = (_class = class ConfirmComponent extends _component2.default {
    cancelConfirm() {
      this.args.onClose();
    }
    confirmed() {
      this.args.onClose();
      this.args.onConfirm();
    }
  }, _applyDecoratedDescriptor(_class.prototype, "cancelConfirm", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelConfirm"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmed"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConfirmComponent);
});