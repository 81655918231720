define("frontend2/initializers/api-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'apiSession', 'service:api-session');
    application.inject('route', 'apiSession', 'service:api-session');
  }
  var _default = _exports.default = {
    initialize
  };
});