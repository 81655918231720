define("frontend2/models/peering-group-member", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let PeeringGroupMemberModel = _exports.default = (_dec = (0, _model.attr)('boolean', {
    defaultValue: true
  }), _dec2 = (0, _model.belongsTo)('customer'), _dec3 = (0, _model.belongsTo)('peering-group'), _dec4 = (0, _model.belongsTo)('price-list'), _dec5 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _dec6 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _dec7 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _dec8 = (0, _model.attr)('number', {
    defaultValue: '0.00'
  }), _class = class PeeringGroupMemberModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "enabled", _descriptor, this);
      _initializerDefineProperty(this, "customer", _descriptor2, this);
      _initializerDefineProperty(this, "peeringGroup", _descriptor3, this);
      _initializerDefineProperty(this, "priceList", _descriptor4, this);
      _initializerDefineProperty(this, "callAttemptFeeNational", _descriptor5, this);
      _initializerDefineProperty(this, "callAttemptFeeInternational", _descriptor6, this);
      _initializerDefineProperty(this, "callConnectFeeNational", _descriptor7, this);
      _initializerDefineProperty(this, "callConnectFeeInternational", _descriptor8, this);
      /* VALIDATION RULES */
      _defineProperty(this, "validation", {
        customer: [(0, _validators.validatePresence)(true)],
        peeringGroup: [(0, _validators.validatePresence)(true)],
        priceList: [(0, _validators.validatePresence)(true)]
      });
    }
    // UI Helpers
    get enabledAsStr() {
      return this.enabled ? 'Enabled' : 'Disabled';
    }
    get hasAttemptFee() {
      return this.callAttemptFeeNational || this.callAttemptFeeInternational;
    }
    get hasConnectFee() {
      return this.callConnectFeeNational || this.callConnectFeeInternational;
    }
    get hasNoConnectFee() {
      return !this.hasAttemptFee && !this.hasConnectFee;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "enabled", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "customer", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "peeringGroup", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "priceList", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "callAttemptFeeNational", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "callAttemptFeeInternational", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "callConnectFeeNational", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "callConnectFeeInternational", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});