define("frontend2/components/alert", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsModal @onHide={{this.args.onClose}} @open={{this.args.show}} as |modal|>
  <modal.header>
    <h4 class="modal-title"><FaIcon @icon="exclamation-triangle"/> Information</h4>
  </modal.header>
  <modal.body>{{yield}}</modal.body>
  <modal.footer as |footer|>
    <BsButton @onClick={{this.args.onClose}} @type="success">
      <FaIcon @icon="check"/>
      OK
    </BsButton>
  </modal.footer>
  </BsModal>
  
  */
  {
    "id": "jcDhZ18P",
    "block": "[[[8,[39,0],null,[[\"@onHide\",\"@open\"],[[30,0,[\"args\",\"onClose\"]],[30,0,[\"args\",\"show\"]]]],[[\"default\"],[[[[1,\"\\n\"],[8,[30,1,[\"header\"]],null,null,[[\"default\"],[[[[1,\"\\n  \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[8,[39,1],null,[[\"@icon\"],[\"exclamation-triangle\"]],null],[1,\" Information\"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[8,[30,1,[\"body\"]],null,null,[[\"default\"],[[[[18,3,null]],[]]]]],[1,\"\\n\"],[8,[30,1,[\"footer\"]],null,null,[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,[[\"@onClick\",\"@type\"],[[30,0,[\"args\",\"onClose\"]],\"success\"]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,1],null,[[\"@icon\"],[\"check\"]],null],[1,\"\\n    OK\\n  \"]],[]]]]],[1,\"\\n\"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"modal\",\"footer\",\"&default\"],false,[\"bs-modal\",\"fa-icon\",\"yield\",\"bs-button\"]]",
    "moduleName": "frontend2/components/alert.hbs",
    "isStrictMode": false
  });
  class ConfirmComponent extends _component2.default {}
  _exports.default = ConfirmComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ConfirmComponent);
});