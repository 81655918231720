define("frontend2/controllers/call-flow", ["exports", "frontend2/controllers/base", "@ember/object", "@glimmer/tracking"], function (_exports, _base, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CallFlowController = _exports.default = (_class = class CallFlowController extends _base.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "masterDragState", _descriptor, this);
      _initializerDefineProperty(this, "showNameDialog", _descriptor2, this);
    }
    async saveCallFlow(cs, route) {
      console.info(`Saving everything in call flow ${cs.name}`);

      // First merge call flow name;
      cs.execute();

      // Then process all actions

      try {
        await this.model.recursiveSave();
      } catch (e) {
        console.error('--- SAVE ERROR: ---');
        console.error(e);
        this.notify.error('Save of call flow failed, please review');
        return;
      }
      this.notify.success('Call flow saved successfully');
      this.router.transitionTo(route);
    }
    async saveCallFlowName(cs) {
      console.info(`Setting call flow name ${cs.name}`);

      // First merge call flow name;
      cs.execute();

      // Then close dialog
      this.showNameDialog = false;
    }
    setDragState(state) {
      console.debug(`Set master drag state to ${state}`);
      this.masterDragState = state;
    }
    showCallFlowDialog() {
      this.showNameDialog = true;
    }
    closeCallFlowDialog() {
      this.showNameDialog = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "masterDragState", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showNameDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveCallFlow", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveCallFlow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveCallFlowName", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveCallFlowName"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDragState", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setDragState"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showCallFlowDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showCallFlowDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeCallFlowDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "closeCallFlowDialog"), _class.prototype), _class);
});