define("frontend2/components/bsz-dropdown", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <BsDropdown as |dd| >
      <dd.button class="dd-button btn-block form-control was-validated" block=true>
          <span class="pull-left">
              {{#if this.selectedItem}}
                  {{yield this.selectedItem}}
              {{else}}
                  {{this.placeholder}}
              {{/if}}
          </span>
          <span class="caret"></span>
      </dd.button>
      <dd.menu as |menu|>
          {{#each this.listWithSelected as |i|}}
              <menu.item>
                  {{#if i._dd_selected}}
                      <a class="selected" {{action "selectItem" i}}>
                        {{yield i}}
                      </a>
                  {{else}}
                      <a class="dd-item"  {{action "selectItem" i}}>
                        {{yield i}}
                      </a>
                  {{/if}}
              </menu.item>
          {{/each}}
      </dd.menu>
  </BsDropdown>
  
  */
  {
    "id": "xaJ66JHj",
    "block": "[[[8,[39,0],null,null,[[\"default\"],[[[[1,\"\\n    \"],[8,[30,1,[\"button\"]],[[24,0,\"dd-button btn-block form-control was-validated\"],[24,\"block\",\"true\"]],null,[[\"default\"],[[[[1,\"\\n        \"],[10,1],[14,0,\"pull-left\"],[12],[1,\"\\n\"],[41,[30,0,[\"selectedItem\"]],[[[1,\"                \"],[18,4,[[30,0,[\"selectedItem\"]]]],[1,\"\\n\"]],[]],[[[1,\"                \"],[1,[30,0,[\"placeholder\"]]],[1,\"\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"caret\"],[12],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[30,1,[\"menu\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,0,[\"listWithSelected\"]]],null]],null],null,[[[1,\"            \"],[8,[30,2,[\"item\"]],null,null,[[\"default\"],[[[[1,\"\\n\"],[41,[30,3,[\"_dd_selected\"]],[[[1,\"                    \"],[11,3],[24,0,\"selected\"],[4,[38,5],[[30,0],\"selectItem\",[30,3]],null],[12],[1,\"\\n                      \"],[18,4,[[30,3]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]],[[[1,\"                    \"],[11,3],[24,0,\"dd-item\"],[4,[38,5],[[30,0],\"selectItem\",[30,3]],null],[12],[1,\"\\n                      \"],[18,4,[[30,3]]],[1,\"\\n                    \"],[13],[1,\"\\n\"]],[]]],[1,\"            \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"]],[2]]]]],[1,\"\\n\"]],[1]]]]],[1,\"\\n\"]],[\"dd\",\"menu\",\"i\",\"&default\"],false,[\"bs-dropdown\",\"if\",\"yield\",\"each\",\"-track-array\",\"action\"]]",
    "moduleName": "frontend2/components/bsz-dropdown.hbs",
    "isStrictMode": false
  });
  let BszDropdownComponent = _exports.default = (_class = class BszDropdownComponent extends _component2.default {
    get placeholder() {
      return this.args.placeholder || 'Please select...';
    }
    selectItem(selectedValue) {
      this.args.onChange(this.args.selectValueProperty ? selectedValue[this.args.selectValueProperty] : selectedValue);
    }
    get listWithSelected() {
      return this.args.list.map(item => {
        if (this.args.selectValueProperty) {
          item._dd_selected = item[this.args.selectValueProperty] === this.args.value;
        } else {
          if (this.args.value) {
            // Try and use ember get (for proxy objects)
            if (this.args.value.get) {
              item._dd_selected = item.id === this.args.value.get('id');
            } else {
              item._dd_selected = item.id === this.args.value.id;
            }
          }
        }
        return item;
      });
    }
    get selectedItem() {
      if (!this.args.list) {
        console.warn('List is empty, nothing to select');
        return null;
      }
      return this.args.list.find(item => {
        if (this.args.selectValueProperty) {
          return this.args.value === item[this.args.selectValueProperty];
        } else {
          // Handle null values (try and find object with null id)
          if (!this.args.value) {
            return item.id === null;
          }

          // Try and use ember get (for proxy objects)
          if (this.args.value.get) {
            return this.args.value.get('id') === item.id;
          } else {
            return this.args.value.id === item.id;
          }
        }
      });
    }
  }, _applyDecoratedDescriptor(_class.prototype, "selectItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectItem"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, BszDropdownComponent);
});