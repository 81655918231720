define("frontend2/templates/admin/customers/loading", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "2JztMF8g",
    "block": "[[[10,0],[14,0,\"container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"row align-items-center mt-5 pt-5\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"col align-self-center text-center\"],[12],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"text-secondary\"],[12],[8,[39,0],null,[[\"@icon\",\"@spin\"],[\"spinner\",true]],null],[13],[1,\"\\n        \"],[10,\"h2\"],[12],[1,\"Loading customer data\"],[13],[1,\"\\n        \"],[10,2],[12],[1,\"Please wait...\"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"fa-icon\"]]",
    "moduleName": "frontend2/templates/admin/customers/loading.hbs",
    "isStrictMode": false
  });
});