define("frontend2/controllers/webhook", ["exports", "frontend2/controllers/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class WebhookController extends _base.default {
    get webhookTypeFilteredList() {
      return this.model.typeList.filter(type => {
        // Show all hooks with id lower than 1000
        if (type.id < 1000) {
          return true;
        }

        // Include partner hooks (id>1000) if user is partner admin
        if (this.apiSession.current.user.userType.level >= 500) {
          return true;
        }
      });
    }
  }
  _exports.default = WebhookController;
});