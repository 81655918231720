define("frontend2/components/playback", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.args.src}}
    <audio controls>
      <source src={{this.args.src}}/>
    </audio>
  {{/if}}
  */
  {
    "id": "s2U1QDcL",
    "block": "[[[41,[30,0,[\"args\",\"src\"]],[[[1,\"  \"],[10,\"audio\"],[14,\"controls\",\"\"],[12],[1,\"\\n    \"],[10,\"source\"],[15,\"src\",[30,0,[\"args\",\"src\"]]],[12],[13],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[],false,[\"if\"]]",
    "moduleName": "frontend2/components/playback.hbs",
    "isStrictMode": false
  });
  class PlaybackComponent extends _component2.default {}
  _exports.default = PlaybackComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PlaybackComponent);
});