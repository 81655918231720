define("frontend2/controllers/base", ["exports", "@ember/controller", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _controller, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let BaseController = _exports.default = (_class = class BaseController extends _controller.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "router", _descriptor, this);
      _initializerDefineProperty(this, "notify", _descriptor2, this);
      _initializerDefineProperty(this, "apiSession", _descriptor3, this);
      /* CONFIRMATION DIALOG */
      _initializerDefineProperty(this, "confirmDialog", _descriptor4, this);
    }
    /* NAVIGATION HELPERS */
    gotoRoute(route) {
      console.debug(`Goto route: ${route}`);
      this.router.transitionTo(route);
    }
    gotoRouteWithModel(route, item) {
      console.debug(`Goto route with model: ${route}`);
      this.router.transitionTo(route, item);
    }
    /* MODEL OPERATIONS */
    async saveCS(changeset, route) {
      console.debug('Saving changeset');
      const creating = changeset.isNew;
      changeset.save().then(() => {
        console.debug('Save complete');
        Promise.allSettled(this.saveDirtyModelRelations(this.model)).then(() => {
          this.notify.success('Saved successfully');

          // Do post save handlers
          if (typeof this.postSaveHook === "function") {
            // Run postSaveHook and wait for promise
            this.postSaveHook(creating).then(transition => {
              console.debug(`Successfully handled post save hook (transition: ${transition})`);
              if (transition) {
                this.router.transitionTo(route);
              }
            });
          } else {
            this.router.transitionTo(route);
          }
        });
      }).catch(err => {
        this.notify.error('Save failed');
        console.info('Save failed');
        console.info(err);
        // Handle server errors
        this.model.errors.forEach(({
          attribute,
          message
        }) => {
          changeset.addError(attribute, message);
        });
        this.model.rollbackAttributes();
      });
    }

    // Iterate all model relationships and check if we need to save any related records
    // Returns list of save promises
    saveDirtyModelRelations(model) {
      let postSavePromises = [];
      model.eachRelationship((name, relationship) => {
        if (relationship.kind === 'hasMany') {
          model[name].forEach(item => {
            if ((item.hasDirtyAttributes || item.isDeleted) && !item.isSaving) {
              console.debug(`Saving changed object on relation ${name}`);
              postSavePromises.push(item.save());
            }
          });
        }
      });
      return postSavePromises;
    }
    deleteModel(route) {
      console.debug('Deleting model');
      this.model.destroyRecord().then(() => {
        console.debug('Delete ok');
        this.notify.success('Deleted OK...');
        // self.notify.success('Deleted OK...');
        this.router.transitionTo(route);
      }).catch(() => {
        this.notify.error('Delete failed');
        console.debug('Deleted failed');
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "notify", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "apiSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "gotoRoute", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "gotoRoute"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "gotoRouteWithModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "gotoRouteWithModel"), _class.prototype), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "confirmDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "saveCS", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveCS"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteModel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteModel"), _class.prototype), _class);
});