define("frontend2/components/format-duration", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component"], function (_exports, _component, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{this.durationStr}}
  
  */
  {
    "id": "9Zl2Au5k",
    "block": "[[[1,[30,0,[\"durationStr\"]]],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "frontend2/components/format-duration.hbs",
    "isStrictMode": false
  });
  class FormatDurationComponent extends _component2.default {
    get durationStr() {
      const sec = this.args.seconds;
      let s = '';

      // Hour
      if (sec / 3600 >= 1) {
        s = s + Math.floor(sec / 3600) % 24 + ':';
      }

      // Seconds + Min
      if (sec >= 0) {
        s = s + ("00" + Math.floor(Math.floor(sec / 60)) % 60).slice(-2) + ':';
        s = s + ("00" + Math.round(sec) % 60).slice(-2);
      } else {
        s = '00:00';
      }
      return s;
    }
  }
  _exports.default = FormatDurationComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, FormatDurationComponent);
});