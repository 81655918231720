define("frontend2/controllers/simple-call-dist", ["exports", "frontend2/controllers/base", "@ember/object", "@glimmer/tracking", "@ember/service"], function (_exports, _base, _object, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let UserController = _exports.default = (_class = class UserController extends _base.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "store", _descriptor, this);
      // Dialogs
      _initializerDefineProperty(this, "showAddMemberDlg", _descriptor2, this);
      // List of sip accounts
      _defineProperty(this, "sipAccountList", []);
      _defineProperty(this, "selectedSipAccount", null);
    }
    // Remove already selected accounts from list
    get availableSipAccounts() {
      return this.sipAccountList.filter(account => {
        return !this.model.members.filter(member => member.get('sipUser.id') === account.id).length;
      });
    }
    openAddMemberDialog() {
      this.showAddMemberDlg = true;
      this.selectedSipAccount = null;
    }
    addMember(item) {
      console.debug(`Adding member ${item.name} to ${this.model.name}`);
      this.store.createRecord('call-group-member', {
        callGroup: this.model,
        type: 1,
        sipUser: item
      });
      this.showAddMemberDlg = false;
    }
    deleteMember(item) {
      console.debug(`Removing member ${item.get('sipUser.name')} from ${this.model.name}`);
      this.model.members.removeObject(item);
      item.deleteRecord();
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showAddMemberDlg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openAddMemberDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openAddMemberDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addMember", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addMember"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deleteMember", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deleteMember"), _class.prototype), _class);
});