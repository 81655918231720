define("frontend2/services/api-session", ["exports", "@ember/service", "@glimmer/tracking", "fetch", "frontend2/config/environment"], function (_exports, _service, _tracking, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApiSessionService = _exports.default = (_class = class ApiSessionService extends _service.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "session", _descriptor, this);
      _initializerDefineProperty(this, "store", _descriptor2, this);
      // Login error message
      _initializerDefineProperty(this, "loginErrorMsg", _descriptor3, this);
      // Current user information
      _initializerDefineProperty(this, "current", _descriptor4, this);
      _initializerDefineProperty(this, "impersonatedCustomer", _descriptor5, this);
      // Embed mode
      _initializerDefineProperty(this, "embedAccessToken", _descriptor6, this);
      _initializerDefineProperty(this, "showEmbeddedHeader", _descriptor7, this);
    }
    get isAuthenticated() {
      return this.session.isAuthenticated;
    }
    get isEmbeddedMode() {
      return Boolean(this.embedAccessToken);
    }
    async login(identification, password) {
      console.debug(`Trying to create session for: ${identification}`);
      return new Promise((resolve, reject) => {
        this.session.authenticate('authenticator:oauth2', identification, password).then(() => {
          console.debug('Successfully authenticated');
          this.loadCurrentUserInfo();
          resolve();
        }).catch(error => {
          console.error('Unable to authenticate new session:');
          console.error(error);
          if (error instanceof TypeError) {
            reject(error.message);
          } else {
            reject(error.responseJSON.error_description);
          }
        });
      });
    }
    logout() {
      console.debug('Invalidating session');
      this.session.invalidate();
      this.current = null;

      // Force page reload to clean up
      window.location.reload(true);
    }
    loadCurrentUserInfo() {
      // Just return is already loaded
      if (this.current || !this.isAuthenticated) {
        return true;
      }
      console.debug('Loading current user information');
      if (this.isEmbeddedMode) {
        this.impersonatedCustomer = null;
      } else {
        const impCid = localStorage.getItem('impCid');
        this.impersonatedCustomer = impCid ? this.store.findRecord('customer', impCid) : null;
      }
      return this.store.queryRecord('current-user', {
        _endpoint: 'users/current'
      }).then(record => {
        this.current = record;
        console.debug('Current user was loaded');
      });
    }
    setEmbeddedMode(configToken, showEmbeddedHeader) {
      const configTokenUnpacked = JSON.parse(atob(configToken));
      this.embedAccessToken = configTokenUnpacked.access;
      this.showEmbeddedHeader = Boolean(Number(showEmbeddedHeader));
      console.info(`Enable embed mode, token: ${this.embedAccessToken} (show headers: ${this.showEmbeddedHeader})`);
    }
    startImpersonating(customer) {
      console.info(`Start impersonate customer id ${customer.id}`);
      // this.store.unloadAll();
      this.loadCurrentUserInfo();
      this.impersonatedCustomer = customer;
      localStorage.setItem('impCid', customer.id);
    }
    stopImpersonating() {
      console.info('Stop impersonating');

      // Clear store and remove impersonation info
      this.store.unloadAll();
      this.impersonatedCustomer = null;
      localStorage.removeItem('impCid');

      // Remove current user to force reload
      this.current = null;
      this.loadCurrentUserInfo();
    }

    /*
     * API Methods
     * -------------------------------
     */

    // Send invitation mail to user
    async inviteUser(user) {
      console.info(`Inviting user ${user.name}`);
      return this.authenticatedRequest('/users/invite_user/', 'POST', {
        user_id: user.id
      });
    }

    // Activate user from token
    async activateUser(token, password) {
      console.info(`Activate user with token ${token}`);
      return this.unauthenticatedRequest('/users/activate/', 'POST', {
        token: token,
        new_password: password
      });
    }
    async getTokenInfo(token) {
      console.info(`Get information for token ${token}`);
      return this.unauthenticatedRequest('/users/get_token_info/', 'POST', {
        token: token
      });
    }

    // Request password recovery
    async reqPasswordRecover(email) {
      return this.unauthenticatedRequest('/users/req_password_recover/', 'POST', {
        email: email
      });
    }

    // Recover password with token and password
    async recoverPassword(token, password) {
      return this.unauthenticatedRequest('/users/recover_password/', 'POST', {
        token: token,
        new_password: password
      });
    }

    // Start number verification
    async verifyPhoneNumber(phoneNumber) {
      console.info(`Starting phone number verification for ${phoneNumber.labelStr}`);
      return this.authenticatedRequest(`/phone-numbers/${phoneNumber.id}/verify`, 'GET');
    }

    // Start number deactivation
    async deactivatePhoneNumber(phoneNumber) {
      console.info(`Starting phone number deactivation for ${phoneNumber.labelStr}`);
      return this.authenticatedRequest(`/phone-numbers/${phoneNumber.id}/deactivate`, 'GET');
    }

    // Start number termination
    async terminatePhoneNumber(phoneNumber) {
      console.info(`Starting phone number termination for ${phoneNumber.labelStr}`);
      return this.authenticatedRequest(`/phone-numbers/${phoneNumber.id}/terminate`, 'GET');
    }

    // Start number termination
    async getSignedUploadURL() {
      console.info(`Fetching signed upload url for media file`);
      return this.authenticatedRequest(`/media-files/get-upload-url`, 'GET');
    }

    /*
     * API Request helpers
     * ---------------------------------
     */

    // Perform unauthenticated request
    async unauthenticatedRequest(path, method, data) {
      console.debug(`Performing unauthenticated request [${method}] ${path}`);

      // Create headers first
      let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      };
      return this.doFetch(path, method, headers, data);
    }

    // Perform authenticated request
    async authenticatedRequest(path, method, data) {
      if (!this.isAuthenticated) {
        console.error('Unable to perform request, not authenticated');
        return Promise.reject();
      }
      console.debug(`Performing authenticated request [${method}] ${path}`);

      // Create headers first
      const authToken = `Bearer ${this.session.data.authenticated.access_token}`;
      let headers = {
        'Content-Type': 'application/json',
        Authorization: authToken,
        Accept: 'application/json'
      };
      const imp_id = localStorage.getItem('impCid');
      if (imp_id) {
        headers['X-USE-CUSTOMER-ID'] = imp_id;
      }
      return this.doFetch(path, method, headers, data);
    }

    // Reorganise fetch promises so fail is non 2xx responses
    async doFetch(path, method, headers, data) {
      return new Promise((resolve, reject) => {
        (0, _fetch.default)(_environment.default.APP.API_HOST + '/api' + path, {
          method: method,
          headers: headers,
          body: JSON.stringify(data)
        }).then(response => {
          if (response.status >= 200 && response.status < 300) {
            response.json().then(data => {
              resolve(data);
            });
          } else {
            response.json().then(error => {
              error._status = response.status;
              reject(error);
            });
          }
        }).catch(error => {
          this.notify.error('An unexpected error occurred');
          console.error(error);
          reject(error);
        });
      });
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "loginErrorMsg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "current", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "impersonatedCustomer", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "embedAccessToken", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "showEmbeddedHeader", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _class);
});