define("frontend2/routes/application", ["exports", "@ember/routing/route", "@ember/object", "@ember-data/adapter/error", "@ember/service"], function (_exports, _route, _object, _error, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ApplicationRoute = _exports.default = (_class = class ApplicationRoute extends _route.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "apiSession", _descriptor, this);
      _initializerDefineProperty(this, "intl", _descriptor2, this);
      _defineProperty(this, "queryParams", {
        embed: {
          refreshModel: false
        },
        page_header: {
          refreshModel: false
        }
      });
    }
    error(error /* transition */) {
      console.error('An error occurred:');
      console.error(error);

      // Handle unauthorized errors
      if (error instanceof _error.UnauthorizedError) {
        console.warn('Unauthorized error');
        if (this.apiSession.embedAccessToken) {
          console.warn('Redirect to ember error page');
          this.transitionTo('embed-error');
        } else {
          console.warn('Unauthorized, redirecting to login');
          this.transitionTo('login');
        }
      }
      let errStr = 'Unknown error';
      if ('errors' in error && error.isAdapterError) {
        // Just grab first error
        const err = error.errors[0];
        errStr = `An unexpected API error<br><b>(${err.status}) ${err.detail}</b>`;
      }
      if ('readyState' in error) {
        try {
          const err = JSON.parse(error.responseText);
          errStr = err.error;
        } catch (ex) {
          errStr = 'Malformed error message from api server??';
        }
      }
      this.notify.alert({
        html: errStr
      }, {
        closeAfter: 10000
      });
    }
    model(params, transition) {
      this.intl.setLocale(['en-gb', 'en']);

      // Exclude these routes from authentication
      const unauthenticatedRoutes = ['login', 'recover-password', 'link.activate', 'link.error', 'link.recover', 'embed-error'];
      if (unauthenticatedRoutes.includes(transition.targetName)) {
        console.debug(`Unauthenticated route: ${transition.targetName}`);
        return;
      }
      console.debug(`Authenticated route: ${transition.targetName}`);

      // Set embedded mode
      if (params.embed) {
        this.apiSession.setEmbeddedMode(params.embed, params.page_header);
      } else {
        if (!this.apiSession.isAuthenticated) {
          console.info('User not authenticated, redirecting to login page');
          this.transitionTo('login');
          return;
        }
      }

      // console.debug('User is authenticated');
      return this.apiSession.loadCurrentUserInfo(true);
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "apiSession", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "error", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype), _class);
});