define("frontend2/routes/admin/customers/index", ["exports", "frontend2/routes/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let AdminCustomersIndexRoute = _exports.default = (_class = class AdminCustomersIndexRoute extends _base.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "controllerName", 'admin/customer-list');
      _defineProperty(this, "queryParams", {
        page: {
          refreshModel: true
        },
        inactive: {
          refreshModel: true
        },
        search: {
          refreshModel: true
        }
      });
    }
    model(params) {
      let q = {};
      if (params.search) {
        q.search = params.search;
      }
      if (params.page) {
        q.page = params.page;
      }
      if (!params.inactive) {
        q['has_activity'] = 1;
      }
      q.include = "home_country,subscriptions,currency,aggregated_statistics";
      q.page_size = 50;

      // Load customers with subscription, so we can show subscription sales on customer list
      // We use query here because findAll makes premature resolve of promise, causing issues for loading substate
      return this.store.query('customer', q);
    }
    loading(transition /* originRoute */) {
      let controller = this.controllerFor('admin/customer');
      controller.set('isLoading', true);
      transition.promise.finally(function () {
        controller.set('isLoading', false);
      });

      // Show page spinner if coming from a different page
      return !(transition.from && transition.from.name === 'admin.customers.index');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "loading", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loading"), _class.prototype), _class);
});