define("frontend2/models/call-flow-action", ["exports", "@ember-data/model", "@glimmer/tracking"], function (_exports, _model, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CallFlowActionModel = _exports.default = (_dec = (0, _model.belongsTo)('call-flow', {
    inverse: 'actions'
  }), _dec2 = (0, _model.attr)('string'), _class = class CallFlowActionModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "callFlow", _descriptor, this);
      _initializerDefineProperty(this, "position", _descriptor2, this);
      _initializerDefineProperty(this, "errorMessage", _descriptor3, this);
      // UI Flags
      _initializerDefineProperty(this, "needsConfig", _descriptor4, this);
    }
    // Save all related objects
    async recursiveSave() {
      console.debug(`Saving call flow action at position ${this.position}`);
      return new Promise((resolve, reject) => {
        this.save().then(() => {
          resolve();
        }, e => {
          console.debug(`Call flow action save error on position ${this.position}`);
          this.errorMessage = e.message;
          reject(e);
        });
      });
    }

    // Rollback all related objects
    recursiveRollback() {
      console.debug(`Rollback call flow action at position ${this.position}`);
    }
    // Returns if action will continue
    get canContinue() {
      // IVR Menus only continue if loop is disabled
      if (this.isIVRMenu) {
        return !this.loop;
      }

      // Hangup and jump call flow  never continue
      else if (this.isHangup || this.isJumpCallFlow) {
        return false;
      }

      // Call groups and direct calls only continue if they have timeout enabled
      else if (this.isCallGroup || this.isCallDirect) {
        return this.hasTimeout;
      }
      return true;
    }

    // UI Helpers
    get isPlayMsg() {
      return this.constructor.modelName === 'call-flow-action-play-msg';
    }
    get isIVRMenu() {
      return this.constructor.modelName === 'call-flow-action-ivr-menu';
    }
    get isHangup() {
      return this.constructor.modelName === 'call-flow-action-hangup';
    }
    get isCallGroup() {
      return this.constructor.modelName === 'call-flow-action-call-group';
    }
    get isJumpCallFlow() {
      return this.constructor.modelName === 'call-flow-action-jump-call-flow';
    }
    get isCallDirect() {
      return this.constructor.modelName === 'call-flow-action-call-direct';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "callFlow", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "position", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorMessage", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "needsConfig", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _class);
});