define("frontend2/components/callflow/base-action", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "frontend2/models/media-file"], function (_exports, _component, _tracking, _object, _service, _mediaFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CallflowBaseActionComponent = _exports.default = (_class = class CallflowBaseActionComponent extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      // Action model
      _initializerDefineProperty(this, "model", _descriptor2, this);
      // State stuff
      _initializerDefineProperty(this, "isCreating", _descriptor3, this);
      _initializerDefineProperty(this, "isCollapsed", _descriptor4, this);
      _initializerDefineProperty(this, "showConfig", _descriptor5, this);
      _initializerDefineProperty(this, "deleteConfirmDialog", _descriptor6, this);
      // Dropdown lists
      _initializerDefineProperty(this, "mediaFileList", _descriptor7, this);
      this.model = this.args.info;

      // If call flow action has needsConfig, open it right away
      if (this.args.info.needsConfig) {
        console.debug('We are creating new action, open config');
        this.isCreating = true;
        this.openConfig();
      }
    }
    toggleCollapsed() {
      this.isCollapsed = !this.isCollapsed;
    }
    openConfig() {
      console.debug('Loading media file list');
      this.mediaFileList = this.store.query('media-file', {
        'mediaType': _mediaFile.MEDIA_FILE_TYPES.CALLFLOW,
        'state': _mediaFile.MEDIA_FILE_STATES.READY
      });
      this.showConfig = true;
    }
    cancelConfig() {
      this.showConfig = false;
      // If we are creating, pressing cancel will remove action again
      if (this.isCreating) {
        this.args.onDelete(this.args.info);
      }
    }
    saveConfig() {
      console.debug('Applying action config');
      this.showConfig = false;
    }
    applyAction(cs) {
      if (cs) {
        console.debug('Applying action');
        cs.execute();
      }
      this.showConfig = false;
      this.model.needsConfig = false;
    }
    confirmDelete() {
      this.deleteConfirmDialog = true;
    }
    confirmedDelete() {
      this.deleteConfirmDialog = false;
      this.args.onDelete(this.args.info);
    }
    setDragInfo(ev) {
      // Add move operation and id of
      console.debug(`Pickup from position ${this.args.info.position}`);
      const di = JSON.stringify({
        operation: 'move',
        from_position: this.args.info.position
      });

      // console.debug(`Setting drag info: ${di}`);
      ev.dataTransfer.setData('application/json', di);
      if (this.args.onDragStateChange) {
        this.args.onDragStateChange(true);
      }
    }
    dragEnd() {
      if (this.args.onDragStateChange) {
        this.args.onDragStateChange(false);
      }
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "model", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isCreating", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "showConfig", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "deleteConfirmDialog", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "mediaFileList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleCollapsed", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggleCollapsed"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openConfig", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelConfig", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "cancelConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "saveConfig", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "saveConfig"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "applyAction", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "applyAction"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "confirmedDelete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "confirmedDelete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setDragInfo", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setDragInfo"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragEnd", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragEnd"), _class.prototype), _class);
});