define("frontend2/components/drop-zone", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object"], function (_exports, _component, _templateFactory, _component2, _tracking, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div
    {{on "drop" this.dropItem}}
    {{on "dragover" this.dragOver}}
    {{on "dragleave" this.dropLeave}}
    {{on "dragenter" this.dropEnter}}
          class="dropZone {{if this.overDropZone "dropZone-over"}} text-center">
  
    {{#if this.args.dragState}}
      {{yield}}
    {{else}}
      <div class="clearZone">Empty Call Action Spot</div>
    {{/if}}
  </div>
  */
  {
    "id": "Wdjbla5b",
    "block": "[[[11,0],[16,0,[29,[\"dropZone \",[52,[30,0,[\"overDropZone\"]],\"dropZone-over\"],\" text-center\"]]],[4,[38,1],[\"drop\",[30,0,[\"dropItem\"]]],null],[4,[38,1],[\"dragover\",[30,0,[\"dragOver\"]]],null],[4,[38,1],[\"dragleave\",[30,0,[\"dropLeave\"]]],null],[4,[38,1],[\"dragenter\",[30,0,[\"dropEnter\"]]],null],[12],[1,\"\\n\\n\"],[41,[30,0,[\"args\",\"dragState\"]],[[[1,\"    \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,0],[14,0,\"clearZone\"],[12],[1,\"Empty Call Action Spot\"],[13],[1,\"\\n\"]],[]]],[13]],[\"&default\"],false,[\"if\",\"on\",\"yield\"]]",
    "moduleName": "frontend2/components/drop-zone.hbs",
    "isStrictMode": false
  });
  let DropZoneComponent = _exports.default = (_class = class DropZoneComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "overDropZone", _descriptor, this);
    }
    dropItem(dragEvent) {
      dragEvent.preventDefault();
      const d = dragEvent.dataTransfer.getData('application/json');
      if (!d) {
        console.error('Drag information is invalid or missing');
        return;
      }
      const info = JSON.parse(d);
      info.to_position_after = this.args.position;
      console.debug(`Drop operation ${info.operation} from position ${info.from_position} to after position ${info.to_position_after}`);
      this.overDropZone = false;
      this.args.onDrop(info);
    }
    dragOver(dragEvent) {
      dragEvent.preventDefault();
      dragEvent.dataTransfer.dropEffect = "move";
    }
    dropEnter() {
      // console.debug('Drag is entered Drop Zone');
      this.overDropZone = true;
    }
    dropLeave() {
      // console.debug('Drag has left drop zone');
      this.overDropZone = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "overDropZone", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "dropItem", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dropItem"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dragOver", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dragOver"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropEnter", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dropEnter"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dropLeave", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "dropLeave"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DropZoneComponent);
});