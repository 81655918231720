define("frontend2/models/network", ["exports", "@ember-data/model", "ember-changeset-validations/validators"], function (_exports, _model, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let NetworkModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _class = class NetworkModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "name", _descriptor, this);
      _initializerDefineProperty(this, "address", _descriptor2, this);
      // @attr('string', {defaultValue: '255.255.255.255'}) netmask;
      _initializerDefineProperty(this, "netmask", _descriptor3, this);
      // Netmask selectbox list
      _defineProperty(this, "netmaskList", [{
        value: '255.255.255.255',
        name: '1 (255.255.255.255)'
      }, {
        value: '255.255.255.254',
        name: '2 (255.255.255.254)'
      }, {
        value: '255.255.255.252',
        name: '4 (255.255.255.252)'
      }, {
        value: '255.255.255.248',
        name: '8 (255.255.255.248'
      }, {
        value: '255.255.255.240',
        name: '16 (255.255.255.240)'
      }, {
        value: '255.255.255.224',
        name: '32 (255.255.255.224)'
      }, {
        value: '255.255.255.192',
        name: '64 (255.255.255.192)'
      }, {
        value: '255.255.255.128',
        name: '128 (255.255.255.128)'
      }, {
        value: '255.255.255.0',
        name: '256 (255.255.255.0)'
      }]);
      /* VALIDATION RULES */
      _defineProperty(this, "validation", {
        name: [(0, _validators.validatePresence)(true)],
        address: [(0, _validators.validatePresence)(true)],
        netmask: [(0, _validators.validatePresence)(true)]
      });
    }
    // UI Helpers
    get networkStr() {
      return this.netmask === '255.255.255.255' ? this.address : `${this.address} / ${this.netmask}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "address", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "netmask", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});