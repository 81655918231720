define("frontend2/models/cdr", ["exports", "@ember-data/model"], function (_exports, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _descriptor20, _descriptor21, _descriptor22;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let CdrModel = _exports.default = (_dec = (0, _model.attr)('string'), _dec2 = (0, _model.attr)('string'), _dec3 = (0, _model.attr)('string'), _dec4 = (0, _model.belongsTo)('sip-trunk'), _dec5 = (0, _model.belongsTo)('sip-trunk'), _dec6 = (0, _model.belongsTo)('rate-destination'), _dec7 = (0, _model.attr)('number'), _dec8 = (0, _model.attr)('number'), _dec9 = (0, _model.attr)('number'), _dec10 = (0, _model.attr)('string'), _dec11 = (0, _model.belongsTo)('phone-number'), _dec12 = (0, _model.attr)('string'), _dec13 = (0, _model.attr)('string'), _dec14 = (0, _model.attr)('boolean'), _dec15 = (0, _model.attr)('string'), _dec16 = (0, _model.attr)('string'), _dec17 = (0, _model.attr)('date'), _dec18 = (0, _model.attr)('number'), _dec19 = (0, _model.attr)('number'), _dec20 = (0, _model.attr)('number'), _dec21 = (0, _model.attr)('string'), _dec22 = (0, _model.attr)('date'), _class = class CdrModel extends _model.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "uuid", _descriptor, this);
      _initializerDefineProperty(this, "pUuid", _descriptor2, this);
      _initializerDefineProperty(this, "direction", _descriptor3, this);
      _initializerDefineProperty(this, "srcSipTrunk", _descriptor4, this);
      _initializerDefineProperty(this, "dstSipTrunk", _descriptor5, this);
      _initializerDefineProperty(this, "dstRateDestination", _descriptor6, this);
      _initializerDefineProperty(this, "dstRateCost", _descriptor7, this);
      _initializerDefineProperty(this, "dstCallAttemptFee", _descriptor8, this);
      _initializerDefineProperty(this, "dstCallConnectFee", _descriptor9, this);
      _initializerDefineProperty(this, "callerIdNumber", _descriptor10, this);
      _initializerDefineProperty(this, "effectiveCid", _descriptor11, this);
      _initializerDefineProperty(this, "destinationNumber", _descriptor12, this);
      _initializerDefineProperty(this, "finalDestinationNumber", _descriptor13, this);
      _initializerDefineProperty(this, "hideCid", _descriptor14, this);
      _initializerDefineProperty(this, "hangupCauseQ850Enum", _descriptor15, this);
      _initializerDefineProperty(this, "dispositionEnum", _descriptor16, this);
      _initializerDefineProperty(this, "startTs", _descriptor17, this);
      _initializerDefineProperty(this, "duration", _descriptor18, this);
      _initializerDefineProperty(this, "billsec", _descriptor19, this);
      _initializerDefineProperty(this, "answersec", _descriptor20, this);
      _initializerDefineProperty(this, "mediaSwitchName", _descriptor21, this);
      _initializerDefineProperty(this, "recordingsRetained", _descriptor22, this);
    }
    // UI Helpers
    get totalCost() {
      return this.billsec / 60 * this.dstRateCost + this.dstCallAttemptFee + this.dstCallConnectFee;
    }
    get shortCall() {
      return this.duration < 30;
    }
    get dispositionStr() {
      switch (this.dispositionEnum) {
        case "BUSY":
          return 'Busy';
        case "INVALID_NUMBER":
          return 'Invalid number';
        case "NO_ANSWER":
          return 'No answer';
        case "TEMP_UNAVAIL":
          return 'Temp. unavailable';
        case "NORMAL":
          return 'Normal';
        case "FAILED":
          return 'Failed';
        case "BARRED":
          return 'Barred';
        default:
          return 'Unknown disposition';
      }
    }
    get directionStr() {
      switch (this.direction) {
        case "1":
          return 'Incoming';
        case "2":
          return 'Outgoing';
        case "3":
          return 'Transferred';
        case "4":
          return 'Local';
        default:
          return 'Unknown direction';
      }
    }
    get hideCidStr() {
      return this.hideCid ? 'Yes (hide number)' : 'No (show number)';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "uuid", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "pUuid", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "direction", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "srcSipTrunk", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "dstSipTrunk", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "dstRateDestination", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "dstRateCost", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "dstCallAttemptFee", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "dstCallConnectFee", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "callerIdNumber", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "effectiveCid", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "destinationNumber", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "finalDestinationNumber", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "hideCid", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "hangupCauseQ850Enum", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "dispositionEnum", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "startTs", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor18 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor19 = _applyDecoratedDescriptor(_class.prototype, "billsec", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor20 = _applyDecoratedDescriptor(_class.prototype, "answersec", [_dec20], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor21 = _applyDecoratedDescriptor(_class.prototype, "mediaSwitchName", [_dec21], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor22 = _applyDecoratedDescriptor(_class.prototype, "recordingsRetained", [_dec22], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});