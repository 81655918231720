define("frontend2/controllers/admin/customer", ["exports", "frontend2/controllers/base", "@ember/object"], function (_exports, _base, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  let AdminCustomerController = _exports.default = (_class = class AdminCustomerController extends _base.default {
    constructor(...args) {
      super(...args);
      // Dropdown lists
      _defineProperty(this, "homeCountryList", []);
      _defineProperty(this, "blacklistList", []);
      _defineProperty(this, "currencyList", []);
      _defineProperty(this, "invoiceTypeList", [{
        value: 0,
        name: 'Invoice via partner'
      }, {
        value: 1,
        name: 'Invoice sent to customer'
      }]);
    }
    get blacklistListWithNone() {
      return [{
        id: null,
        name: '(Do not use blacklist)'
      }].concat(this.blacklistList.toArray());
    }
    loginAsCustomer(customer) {
      this.apiSession.startImpersonating(customer);
      this.router.transitionTo('index');
    }
  }, _applyDecoratedDescriptor(_class.prototype, "loginAsCustomer", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loginAsCustomer"), _class.prototype), _class);
});