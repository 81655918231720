define("frontend2/controllers/short-cid", ["exports", "frontend2/controllers/base", "@ember/object", "@ember/object/computed", "@glimmer/tracking"], function (_exports, _base, _object, _computed, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  let ShortCidController = _exports.default = (_dec = (0, _computed.setDiff)('phoneNumberList', 'model.phoneNumbers'), _class = class ShortCidController extends _base.default {
    constructor(...args) {
      super(...args);
      // Provide list of phone numbers not already selected
      _initializerDefineProperty(this, "phoneNumberList", _descriptor, this);
      _initializerDefineProperty(this, "usablePhoneNumbers", _descriptor2, this);
      _initializerDefineProperty(this, "selectedPhoneNumber", _descriptor3, this);
      _initializerDefineProperty(this, "showAddPhoneNumberDlg", _descriptor4, this);
    }
    // Only use available phone numbers
    get availablePhoneNumberList() {
      return this.usablePhoneNumbers.filter(pn => pn.isAvailable);
    }
    gotoCIDReport(cid) {
      // Go back 30 days and interval
      const reportTS = Math.floor(Date.now() / 1000) - 30 * 86400;

      // Build list of phone number id's
      const pnList = [];
      cid.phoneNumbers.forEach(function (pn) {
        pnList.push(parseInt(pn.get('id')));
      });
      this.router.transitionTo('statistics.cid', 'day', reportTS, {
        queryParams: {
          sg: pnList
        }
      });
    }
    openAddPhoneNumberDialog() {
      this.showAddPhoneNumberDlg = true;
    }
    selectPhoneNumber(pn) {
      this.selectedPhoneNumber = pn;
    }
    removePhoneNumber(pn) {
      console.debug(`Remove phone number ${pn.name}`);
      this.model.phoneNumbers.removeObject(pn);
    }
    addPhoneNumber() {
      console.debug(`Adding phone number ${this.selectedPhoneNumber}`);
      this.model.phoneNumbers.pushObject(this.selectedPhoneNumber);
      this.selectedPhoneNumber = null;
      this.showAddPhoneNumberDlg = false;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "phoneNumberList", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "usablePhoneNumbers", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "gotoCIDReport", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "gotoCIDReport"), _class.prototype), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "selectedPhoneNumber", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "showAddPhoneNumberDlg", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "openAddPhoneNumberDialog", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "openAddPhoneNumberDialog"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectPhoneNumber", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectPhoneNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removePhoneNumber", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removePhoneNumber"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addPhoneNumber", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addPhoneNumber"), _class.prototype), _class);
});