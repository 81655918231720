define("frontend2/routes/call-routing/edit", ["exports", "frontend2/routes/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class CallRoutingEditRoute extends _base.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "controllerName", 'call-routing');
    }
    setupController(controller, model) {
      super.setupController(controller, model);
      // Get sip connections for select box
      controller.sipAccountList = this.store.findAll('sip-trunk');
      controller.callGroupList = this.store.findAll('call-group');

      // Create call flow if needed
      if (!model.callFlow) {
        model.callFlow = this.store.createRecord('call-flow', {
          name: `CF_PN_${model.get('country.dial')}${model.startNational}`,
          template: false
        });
        console.debug(`Created new call flow: ${model.callFlow.name}`);
      }
    }
    model(params) {
      return this.store.findRecord('phone-number', params.number_id, {
        reload: true,
        include: 'call_flow'
      });
    }
  }
  _exports.default = CallRoutingEditRoute;
});