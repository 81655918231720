define("frontend2/templates/link/error", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "0n9OQClN",
    "block": "[[[3,\" Page Header start \"],[1,\"\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-header\"],[12],[1,\"\\n        \"],[10,\"h2\"],[12],[8,[39,0],null,[[\"@icon\"],[\"exclamation-triangle\"]],null],[1,\" Error\"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[3,\" Page Header end \"],[1,\"\\n\\n\"],[10,0],[14,0,\"container\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"jumbotron\"],[12],[1,\"\\n\"],[41,[33,2],[[[1,\"          \"],[10,\"h2\"],[12],[1,\"Unknown error\"],[13],[1,\"\\n          \"],[10,2],[12],[1,\"Unable to process the request, because of an unknown error.\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n              If problem persists, contact the administrator to resolve the problem.\"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[33,3],[[[1,\"          \"],[10,\"h2\"],[12],[1,\"Invalid token\"],[13],[1,\"\\n          \"],[10,2],[12],[1,\"Unable to process the request, because the token is invalid or expired. Please check the link is complete\\n              and make sure it's correct.\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[1,\"\\n              If problem persists, contact the administrator to resolve the problem.\"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[13]],[],false,[\"fa-icon\",\"if\",\"errUnknown\",\"errInvalid\"]]",
    "moduleName": "frontend2/templates/link/error.hbs",
    "isStrictMode": false
  });
});